<template>
  <img src="../../assets/images/WhySageye.jpg" alt="WhySageye" class="whySageye_img">
</template>
<script>
 
  export default {
    name: 'WhySageyeImage',
    data() {
      return {
      }
    },
    components: {
     
    },
    methods: {
    }
}
</script>
<style scoped>
  .whySageye_img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 93%;
  }

  @media only screen and (max-width: 480px) {
    .whySageye_img{
      display: block;
      margin-left: auto;
      margin-right: auto;
      /* width: 60%; */
    }
  }
  
</style>
