<template>
  <figure class="image is-inline-block">
    <img class="img_style" src="../../assets/images/sageye_story_builders.jpeg"/>
  </figure>
</template>
<script>
  export default {
    name: 'LandingPageImage',
    data() {
      return {
        
      }
    },
    components: {
      
    },
    methods: {
    }
  }
</script>
<style scoped>

.img_style{
  /* -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
  transition: background-color 300ms;  */
  display: block;
  margin:0 auto;
  height: auto; 
  width: 98%;
}

</style>
